import { ServiceFactory } from '@/services/ServiceFactory'
const _bookingsService = ServiceFactory.get('BookingsService')
const _citiesService = ServiceFactory.get('CitiesService')
export default {
  data () {
    return {
      loadingTable: true,
      bookingsActive: [],
      bookingsByBusiness: [],
      headers: [
        // {
        //   text: 'Negocio',
        //   value: 'business.name',
        //   sortable: true
        // },
        {
          text: 'Nombre',
          value: 'contact.name',
          sortable: true
        },
        {
          text: 'Celular',
          value: 'contact.cellphone',
          sortable: false
        },
        {
          text: 'Fecha',
          value: 'date',
          sortable: false
        },
        {
          text: 'Hora',
          value: 'time',
          sortable: false
        },
        {
          text: 'Cód. reserva',
          value: 'code',
          sortable: true
        },
        {
          text: 'Cód. promo.',
          value: 'meta.promoCode',
          sortable: true
        },
        {
          text: 'Cant. pers.',
          value: 'numberPeople',
          sortable: false
        },
        {
          text: 'Desc.',
          value: 'discount',
          sortable: false
        },
        // {
        //   text: '¿Autenticado?',
        //   value: 'contact',
        //   sortable: false
        // },
        {
          text: 'Penalizado',
          value: 'penalty',
          sortable: false
        },
        {
          text: 'Estado',
          value: 'state',
          sortable: false
        },
        {
          text: 'Acciones',
          value: 'options',
          sortable: false
        }
      ],
      // schedules: [
      //   '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
      //   '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30',
      //   '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59', '00:00', '00:30',
      //   '01:00', '01:30', '02:00', '02:30', '03:00'
      // ],
      // postBooking: {
      //   clientName: '',
      //   numberPeople: 0,
      //   date: '',
      //   time: '',
      //   comments: ''
      // },
      page: 1,
      showNewBookingModal: false,
      menu: false,
      dialog: false,
      menu2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      loading: false,
      zbBarId: '',
      code: '',
      loading2: false,
      showTable: false,
      cities: [],
      cityId: null
    }
  },
  // watch: {
  //   menu (val) {
  //     val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
  //   }
  // },
  mounted () {
    this.selectCity()
  },
  methods: {
    async selectCity () {
      this.cities = await _citiesService.index('Bolivia')
      // console.log(this.cities.cities)
      var lpID = ''
      this.cities.cities.forEach(el => {
        if (el.name === 'La Paz') {
          lpID = el.zbCityId
        }
      })
      this.cityId = lpID
      this.initialize()
    },
    async initialize () {
      this.code = ''
      this.loadingTable = true
      // if (this.$route.params.id) {
      //   this.zbBarId = this.$route.params.id
      //   console.log(this.$route.params.id, 'booking active component')
      try {
        // this.barData = await _barsService.show(this.$route.params.id)
        this.bookingsActive = await _bookingsService.getAll(this.date.toString(), this.cityId)
        console.log(this.bookingsActive)

        this.loadingTable = false

        var bs = []
        var ls = []
        var lsindex = null

        // bookingsList.filter((element, index) => {
        //   console.log(element.business.zbBarId)
        //   if (!bs.includes(element.business.zbBarId)) {
        //     bs.push(element.business.zbBarId)
        //     // console.log()
        //   }
        // })
        // console.log(bs)

        this.bookingsActive.bookings.filter((element, index) => {
          console.log('======================')
          console.log(element.business.zbBarId, bs)
          if (!bs.includes(element.business.zbBarId)) {
            console.log('no incluye')
            lsindex = ls.push({
              id: element.business.zbBarId,
              name: element.business.name,
              bookings: []
            })
            bs.push(element.business.zbBarId)
            console.log(ls[lsindex - 1])
          }
          // else {
          //   ls[lsindex - 1].bookings.push(element)
          //   // console.log('a')
          // }
          var indexLS = ls.findIndex(i => i.id === element.business.zbBarId)
          console.log(indexLS)
          ls[indexLS].bookings.push(element)
          console.log(lsindex)
          // return this.bookingsActive.bookings.indexOf(element) === index;
          console.log('======================')
        })

        console.log(ls)
        ls.sort((a, b) => {
          const nameA = a.name.toUpperCase() // ignore upper and lowercase
          const nameB = b.name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        this.bookingsByBusiness = ls

        // console.log(this.bookingsActive)
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al consultar las reservas del negocio, vuelva a la lista de negocios e intente nuevamente'
        })
        this.loadingTable = false
        console.log(error)
      }
      // } else {
      //   console.log('sin parametros')
      //   this.loadingForm = false
      // }
    },
    async verifyCode () {
      console.log(this.code)
      this.loading2 = true
      try {
        this.$swal({
          title: '¿Desea verificar el código "' + this.code + '"?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const verifyResp = await _bookingsService.verify(this.code)
              console.log(verifyResp)
              this.loading2 = false
              this.$swal(
                'Reserva verificada',
                'Se cambió el estado de la reserva a completado',
                'success'
              ).then(async (result) => {
                this.initialize()
              })
            } catch (error) {
              this.loading2 = false
              this.$notify({
                title: 'Error:',
                type: 'error',
                text: error.msg
              })
            }
          } else {
            this.loading2 = false
          }
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al verificar la reserva, vuelva a intentarlo nuevamente'
        })
        this.loading2 = false
      }
    },
    async editBooking (id, code) {
      console.log(id)
      const { value: numberPeople } = await this.$swal({
        title: `Editar número de personas en la reserva con código: ${code}`,
        input: 'text',
        inputPlaceholder: 'Ingrese el nuevo número de personas',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        }
      })
      if (numberPeople) {
        console.log(numberPeople)
        try {
          const editResp = await _bookingsService.people(id, parseInt(numberPeople))
          console.log(editResp)
          this.$notify({
            type: 'success',
            text: 'Número de personas editado correctamente'
          })
          this.initialize()
        } catch (error) {
          console.log(error)
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al editar el número de personas, vuelva a intentarlo nuevamente'
          })
        }
      }
    },
    async cancelBooking (id, code) {
      console.log(id)
      const { value: cancelPass } = await this.$swal({
        title: `Cancelar la reserva con código: ${code}`,
        input: 'password',
        inputPlaceholder: 'Ingrese su contraseña para cancelar la reserva',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        }
      })
      if (cancelPass) {
        console.log(cancelPass)
        try {
          const cancelResp = await _bookingsService.cancel(id, { password: cancelPass })
          console.log(cancelResp)
          this.$notify({
            type: 'success',
            text: 'Reserva cancelada correctamente'
          })
          this.initialize()
        } catch (error) {
          console.log(error)
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al cancelar la reserva, vuelva a intentarlo nuevamente'
          })
        }
      }
    },
    verifyBooking (id, code) {
      console.log(id, code)
      this.code = code
      // console.log(this.code)
      this.verifyCode()
    },
    async removePenalty (id, code) {
      try {
        this.$swal({
          title: '¿Desea quitar la penalización le la reserva "' + code + '"?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const verifyResp = await _bookingsService.removePenalty(id)
              console.log(verifyResp)
              // this.loading2 = false
              this.$swal(
                'Penalización removida',
                'Se quitó la penalización de la reserva',
                'success'
              ).then(async (result) => {
                this.initialize()
              })
            } catch (error) {
              // this.loading2 = false
              this.$notify({
                title: 'Error:',
                type: 'error',
                text: error.msg
              })
            }
          } else {
            // this.loading2 = false
          }
        })
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al quitar la penalización de la reserva, vuelva a intentarlo nuevamente'
        })
      }
    }
    // editCategory (id) {
    //   // console.log(id)
    //   this.$router.push({ name: 'member-form', params: { id } })
    // },
    // destroyCategory (id) {
    //   this.$swal({
    //     title: '¿Está seguro de eliminar la categoria?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sí, eliminar',
    //     cancelButtonText: 'Cancelar'
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       try {
    //         // await _newsCategoriesService.destroy(id)
    //         this.$swal(
    //           'Categoría eliminada',
    //           'Se eliminó correctamente',
    //           'success'
    //         ).then(async (result) => {
    //           this.initialize()
    //         })
    //       } catch (error) {
    //         this.$notify({
    //           title: 'Error:',
    //           type: 'error',
    //           text: error.message + ', no se pudo eliminar.'
    //         })
    //       }
    //     }
    //   })
    // },
    // goNewForm (id, payload = null) {
    //   // console.log(id)
    //   this.$router.push({ name: 'member-form', params: { type: 'addMember', id } })
    // },
    // showModal () {
    //   if (this.showNewBookingModal === false) {
    //     this.showNewBookingModal = true
    //   }
    // },
    // save (date) {
    //   this.$refs.menu.save(date)
    // },
    // async sumbitBooking () {
    //   this.loading = true
    //   // console.log('guardar reserva')
    //   // console.log(this.postBooking)
    //   if (this.$route.params.id) {
    //     try {
    //       this.postBooking.numberPeople = parseInt(this.postBooking.numberPeople)
    //       await _bookingsService.create(this.$route.params.id, this.postBooking)
    //       this.dialog = false
    //       this.$notify({
    //         type: 'success',
    //         text: 'Datos modificados correctamente'
    //       })
    //       this.loading = false
    //       this.initialize()
    //       // this.$router.push({ name: 'companies' })
    //       // console.log(this.modules.modules[0])
    //     } catch (error) {
    //       this.$notify({
    //         type: 'error',
    //         text: error.msg
    //       })
    //       this.loading = false
    //       this.disabled = false
    //       // console.info(error)
    //     }
    //   } else {
    //     this.$notify({
    //       type: 'error',
    //       text: 'Sin datos del negocio'
    //     })
    //   }
    // }
  }
}
